
import Stepper from "@/Modules/Stepper";
import { homeAction, homeState, promptResponse } from "@state/home";
import { useSetAtom } from "jotai";
import { useEffect } from "react";
import { useSnapshot } from "valtio";
import EnterPrompt from "./subs/EnterPrompt";
import SelectProduct from "./subs/SelectProduct";

export default function CreateDesignBlock({ auth, samplePrompt }) {

    const snapHome = useSnapshot(homeState);
    const setResponse = useSetAtom(promptResponse);

    const steps = [
        {
            value: 0,
            label: "Design Your Graphics",
            sublabel: "Enter your design prompt and select from result",
            onClick: () => homeAction.setActiveStep(0),
            disabled: snapHome.activeStep === 0
        },
        {
            value: 1,
            label: "Pick Your Product",
            sublabel: "Select which product you want to print",
            onClick: () => homeAction.setActiveStep(1),
            disabled: snapHome.activeStep <= 1
        },
        {
            value: 2,
            label: "Place Your Order",
            sublabel: "Place your order details",
            onClick: null,
            disabled: snapHome.activeStep <= 2
        }
    ];

    useEffect(() => {
        setResponse(samplePrompt);
        homeAction.setActiveStep(0);
    }, [samplePrompt])

    return (
        <div className="w-screen h-full py-6 bg-muted">
            <div className="max-w-7xl w-full mx-auto">
                <div className="flex flex-col justify-center py-6 items-center w-full">
                    <h1 data-aos="fade-down" className="md:text-3xl text-lg mb-4 text-center font-semibold font-nunito italic text-primary uppercase">
                        Create Your Own Design in 3 Steps
                    </h1>
                </div>

                <div className="w-full flex flex-col gap-4">
                    <Stepper steps={steps} activeStep={snapHome.activeStep} />
                    {snapHome.activeStep === 0 && <EnterPrompt auth={auth} />}
                    {snapHome.activeStep === 1 && <SelectProduct />}
                </div>
            </div>
        </div>
    );
}
